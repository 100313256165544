<template>

  <router-link class="directory-link" to="/directory"><img class="menu-icon" src="../assets/images/SocialMediaIcons/menu.svg" alt="menu-icon"></router-link>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>


<style scoped lang="scss">




.directory-link{
  width: 10%;
  .menu-icon{
    //margin: 0;
    margin-top: 1rem;
    width: 90%;
    height: 90%;

  }
}
</style>