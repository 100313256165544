<template>
  <NotFoundModal />
</template>

<script>

import NotFoundModal from "@/components/NotFoundModal.vue";

export default {
  components: {NotFoundModal},

  data() {
    return {}
  },
}
</script>


<style scoped lang="scss">

</style>